<template>
  <div>
    <div v-if="localQuestion">
      <b-field class="pb-2"
        ><p class="subtitle">{{ localQuestion.title }}</p></b-field
      >
      <b-field v-if="localQuestion.description"
        ><p class="subtitle is-6 is-italic">
          {{t('forms.description')}}:{{ localQuestion.description }}
        </p></b-field
      >

      <div class="columns is-centered">
        <div class="column is-6">
          <b-field v-if="currentImage">
            <b-image :src="currentImage" ratio="16by9"> </b-image>
          </b-field>
        </div>
      </div>
      <b-field
        ><p class="subtitle is-5">{{t('question.text')}}: {{ localQuestion.text }}</p>
      </b-field>
      <b-field>
        <b-input
          type="textarea"
          :value="localQuestion.note"
          @input="answerChanged"
        ></b-input>
      </b-field>
    </div>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { SERVER } from '@/store/config_apis'

export default {
  name: "ExamsMentor",
  mixins: [CommonHelpers],
  components: {},

  props: {
    question: { type: Object, required: true },
  },

  data: function () {
    return {
      localQuestion: null,
      tmpValue: null,
      questionInterval: null,
    };
  },

  computed: {
    currentImage() {
      if (this.question.image) {
        return `${SERVER}/api/MediaFile/File/${this.question.image}`;
      }

      return null;
    },
  },

  watch: {
    question() {
      this.localQuestion = JSON.parse(JSON.stringify(this.question));
    },
  },

  mounted() {
    this.localQuestion = JSON.parse(JSON.stringify(this.question));
    this.questionInterval = setInterval(() => {
      this.question.time++;
    }, 1);
  },

  destroyed() {
    clearInterval(this.questionInterval);
  },

  methods: {
    answerChanged(value) {
      this.question.note = value;
      this.$emit=("answerChanged",value)
      if (value) {
        this.$emit("answered", true);
      } else {
        this.$emit("answered", false);
      }
    },
  },
};
</script>

<style scoped>
</style>