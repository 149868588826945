<template>
  <div class="home">
    <ExamComboStart :idClassroom="$route.params.idClassroom"/>
  </div>
</template>

<script>
// @ is an alias to /src
import ExamComboStart from '@/components/exam/examCombo/ExamComboStart'

export default {
  name: 'ExamComboStartView',
  components: {
    ExamComboStart
  }
}
</script>
