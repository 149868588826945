<template>
  <div class="box">
    <div class="columns">
      <div class="column">
        <div><p>{{t('exam.counterQuestions')}} ({{t('exam.counterQuestionsInfo')}}):</p></div>
        <div>{{ answeredQuestion }} / {{ localQuestionList.length }}</div>
      </div>
      <div class="column">
        <div><p>{{t('exam.timeToEnd')}}:</p></div>
        <div v-if="timeOver">{{t('exam.timeOver')}}</div>
        <div v-else>
          <strong>{{ formattedTimeLeft }}</strong>
        </div>
      </div>
      <div class="column">
        <b-button
          icon-left="close"
          class="is-danger"
          :loading="isLoading"
          @click="examEnded"
          :disabled="isEnded"
        >
          {{t('exam.stop')}}
        </b-button>
      </div>
    </div>
    <div class="columns" v-if="userExam && !isEnded && !timeOver">
      <div class="column is-2">
        <b-field v-for="(question, index) in localQuestionList" :key="index">
          <b-button
            @click="indexQuestionSelect = index"
            :class="{ 'is-primary': indexQuestionSelect === index }"
            expanded
          >
            <p>
              <span
                ><b-icon
                  v-if="question.isAnswered"
                  icon="check"
                  size="is-small"
                ></b-icon
              ></span>
              {{t('question.header')}} {{ index + 1 }}
            </p>
          </b-button>
        </b-field>
      </div>
      <div class="column box notification">
        <div v-if="activeQuestion" class="p-2">
          <div v-if="activeQuestion.type === 0">
            <template v-if="activeQuestion.hasSingleAnswer">
              <ExamQuestionRadio
                :question="activeQuestion"
                @answered="buttonAnswered"
              ></ExamQuestionRadio>
            </template>
            <template v-else>
              <ExamQuestionCheckbox
                :question="activeQuestion"
                @answered="buttonAnswered"
              ></ExamQuestionCheckbox>
            </template>
          </div>
          <div v-else-if="activeQuestion.type === 1">
            <ExamQuestionTextArea
              :question="activeQuestion"
              @answered="buttonAnswered"
            ></ExamQuestionTextArea>
          </div>
          <div v-else>
            <p>{{t('validation.errorReportToTeacher')}}</p>
          </div>
          <hr />
          <div class="buttons is-centered">
            <b-button
              icon-left="arrow-left"
              @click="previousQuestion"
              :disabled="previousDisabled"
              >{{t('common.preview')}}</b-button
            >
            <b-button
              icon-right="arrow-right"
              :disabled="nextDisabled"
              @click="nextQuestion"
              >{{t('common.next')}}</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <b-loading v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import ExamQuestionCheckbox from "./ExamQuestionCheckbox";
import ExamQuestionTextArea from "./ExamQuestionTextArea";
import ExamQuestionRadio from "./ExamQuestionRadio";
import { Action } from "@/store/config_actions";
import moment from "moment";

export default {
  name: "ExamsQuizController",
  mixins: [CommonHelpers],
  components: {
    ExamQuestionCheckbox,
    ExamQuestionTextArea,
    ExamQuestionRadio,
  },

  props: {
    idUserExam: { type: String, required: true },
  },

  data: function () {
    return {
      userExam: null,

      //-----------
      activeQuestion: null,
      idQuestionSelect: null,
      indexQuestionSelect: null,
      localExam: {},
      isStarted: false,
      isPreparation: true,
      isEnded: false,
      examInterval: null,
      time: null,
      timeUser: 0,
      previousDisabled: false,
      nextDisabled: false,
      isLoading: false,
      localQuestionList: [],
      questionButton: {
        questionID: "",
        isAnswered: false,
      },
      timeOver: false,
      timeLeft: 0,
      examTypeTest: 0,
    };
  },

  mounted() {
    this.getUserExam();
  },

  watch: {
    indexQuestionSelect() {
      this.activatedQuestion();
      this.activeNavigationQuestion();
    },

    timeUser() {
      if (this.userExam.maxTime * 60 - this.timeUser <= 0) {
        this.timeOver = true;
        this.examEnded();
      }
    },
  },

  created() {},

  computed: {
    answeredQuestion() {
      let answeredQ = this.localQuestionList.filter((q) => q.isAnswered);
      if (answeredQ) {
        return answeredQ.length;
      } else {
        return 0;
      }
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;

      const timeMinutes = Math.floor(timeLeft / 60);
      const hours = Math.floor(timeMinutes / 60);
      let minutes = timeMinutes % 60;
      let seconds = timeLeft % 60;

      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${hours}:${minutes}:${seconds}`;
    },
  },

  methods: {
    /** set isAnswered */
    buttonAnswered(answered) {
      this.localQuestionList[this.indexQuestionSelect].isAnswered = answered;
      //this.updateUserExam();
    },

    /**  */
    nextQuestion() {
      if (this.indexQuestionSelect < this.userExam.questions.length - 1) {
        // if (this.localQuestionList[this.indexQuestionSelect].isAnswered) {
        //   this.updateUserExam();
        // }
        this.indexQuestionSelect++;
      }
    },

    /** */
    previousQuestion() {
      if (this.indexQuestionSelect > 0) {
        this.indexQuestionSelect--;
      }
    },

    /** disabled button next and previous */
    activeNavigationQuestion() {
      if (this.indexQuestionSelect > 0) {
        this.previousDisabled = false;
      } else {
        this.previousDisabled = true;
      }

      if (this.indexQuestionSelect < this.userExam.questions.length - 1) {
        this.nextDisabled = false;
      } else {
        this.nextDisabled = true;
      }
    },

    /** set activatedQuestion  */
    activatedQuestion() {
      let question = this.localQuestionList[this.indexQuestionSelect];
      if (question) {
        let baseQuestion = this.userExam.questions.find(
          (q) => q.id === question.questionID
        );
        this.activeQuestion = this.userExam.questions[
          this.userExam.questions.indexOf(baseQuestion)
        ];
      }
    },

    /** Start exam */
    examStart() {
      this.isStarted = true;
      this.indexQuestionSelect = 0;
      this.examInterval = setInterval(() => {
        let difference = moment(this.userExam.created).diff(
          moment(),
          "seconds"
        );
        this.timeLeft = this.userExam.maxTime * 60 + difference;
        this.timeUser = difference * -1;
      }, 1000);
    },

    /** Ended exam and send to server */
    examEnded() {
      this.isEnded = true;
      clearInterval(this.examInterval);
      if (this.userExam.type === this.examTypeTest) {
        this.userExam.isFinished = true;
      }
      this.updateUserExam();
    },

    /** Create loclaQuestions to list button */
    createLocalQuestion(questions) {
      this.localQuestionList = [];
      var newButtonQuestion;
      questions.forEach((element) => {
        newButtonQuestion = {
          questionID: element.id,
          isAnswered: false,
        };
        this.localQuestionList.push(newButtonQuestion);
      });
    },

    updateUserExam() {
      this.userExam.time = this.timeUser;
      this.$store
        .dispatch(Action.USEREXAM_UPDATE, this.userExam)
        .then((payload) => {
          if (payload.type === this.examTypeTest) {
            if (payload.isFinished) {
              this.mSuccessSnack(this.t('exam.sended'));
            } else {
              this.mDangerSnack(this.t('exam.errorSending'));
            }
          } else {
            this.$emit("endQuiz", payload.id)
          }
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          //this.isLoading = false;
        });
    },

    /** Get UserExam */
    getUserExam() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.USEREXAM_GET_EXAM_BY_ID, this.idUserExam)
        .then((payload) => {
          if (!payload.isFinished) {
            this.userExam = payload;
            this.createLocalQuestion(payload.questions);
            //this.time = payload.maxTime - payload.time;

            this.examStart();
          } else {
            this.isEnded = true;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>