<template>
  <div class="box">
    <div class="box">
      <div class="columns">
        <div class="column">
          <p class="subtitle">{{t('classroom.hedaer')}}: {{ tempClassroom.name }}</p>
          <template v-if="tempClassroom.exam">
            <p class="subtitle">{{t('exam.header')}}: {{ tempClassroom.exam.title }}</p>
            <p class="subtitle is-8 is-italic">
              {{t('exam.description')}}: {{ tempClassroom.exam.description }}
            </p>
            <p class="subtitle is-8">
              {{t('exam.maxTime')}}:
              {{ tempClassroom.exam.maxTime }} min
            </p>
          </template>
          <div class="buttons is-centered">
            <b-button
              icon-left="play"
              class="is-success"
              :disabled="isStarted || isFinished"
              :loading="isLoadingUserExam"
              @click="examStart"
            >
              {{ buttonStart }}
            </b-button>
          </div>
        </div>
        <div class="column" v-if="isFinished">
          <p>{{t('validation.notContinueEndedExam')}}</p>
        </div>
        <b-loading v-model="isLoading"></b-loading>
      </div>
    </div>
    <div class="box" v-if="isStarted">
      <ExamsQuizController :idUserExam="idUserExam" @endQuiz="openImagePart" />
    </div>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "@/store/config_actions";
import ExamsQuizController from "./../examQuiz/ExamQuizController";

export default {
  name: "ExamComboStart",
  mixins: [CommonHelpers],
  components: {
    ExamsQuizController,
  },

  props: {
    idClassroom: { type: String, required: true },
  },

  data: function () {
    return {
      newUserExam: null,
      tempClassroom: {},
      isStarted: false,
      isEnded: false,
      isLoading: true,
      isLoadingUserExam: false,
      buttonStart: this.t('exam.startExam'),
      idUserExam: null,
      isFinished: false,
    };
  },

  mounted() {
    this.getClassroom();
    this.checkExistingUserExam();
  },

  watch: {},

  created() {},

  computed: {},

  methods: {
    /** Start exam */
    examStart() {
      if (this.idUserExam) {
        this.isStarted = true;
      } else {
        this.createUserExam();
      }
    },

    openImagePart(id){
        this.$router.push({
          name: "examimagestudent",
          params: { idUserExam: id },
        });
    },

    updateUserExam() {
      //this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOM_UPDATE, this.userExam)
        .then(() => {
          this.mSuccessSnack(this.t('exam.sended'));
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          //this.isLoading = false;
        });
    },

    /* Check if exist UserExam*/
    checkExistingUserExam() {
      this.isLoadingUserExam = true;
      this.$store
        .dispatch(Action.USEREXAM_GET_MY_EXAMS_IN_CLASS, this.idClassroom)
        .then((payload) => {
          if (payload.length > 0) {
            if (!payload[0].isFinished) {
              this.buttonStart = this.t('common.continue');
              this.idUserExam = payload[0].id;
              this.isStarted = true;
            } else {
              this.isFinished = true;
            }
            if (payload.length > 1) {
              this.mDangerSnack(this.t('validation.moreUserExamCreated'));
            }
          } else {
            this.buttonStart = this.t('exam.startExam');
          }
          this.isLoadingUserExam = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    /** Create new UserExam*/
    createUserExam() {
      this.$store
        .dispatch(Action.USEREXAM_CREATE, this.tempClassroom)
        .then((payload) => {
          this.idUserExam = payload.id;
          this.isStarted = true;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
        });
    },

    /** Get UserExam */
    getUserExam(id) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.USEREXAM_GET_EXAM_BY_ID, id)
        .then((payload) => {
          this.newUserExam = payload;
          this.time = payload.maxTime - payload.time;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    /** Get current classroom */
    getClassroom() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOM_GET_ONE_BY_ID, this.idClassroom)
        .then((payload) => {
          this.tempClassroom = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
        });
    },
  },
};
</script>

<style scoped>
</style>