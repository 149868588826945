<template>
  <div>
    <div v-if="localQuestion">
      <b-field class="pb-2"><p class="subtitle">{{localQuestion.title}}</p></b-field>
      <b-field v-if="localQuestion.description"><p class="subtitle is-6 is-italic" >{{t('forms.description')}}:{{localQuestion.description}}</p></b-field>
      <div class="columns is-centered">
        <div class="column is-6">
          <b-field v-if="currentImage">
            <b-image :src="currentImage" ratio="16by9"> </b-image>
          </b-field>
        </div>
      </div>
      <b-field class="pb-2">
        <p class="subtitle is-5">{{t('question.text')}}: {{ localQuestion.text }}</p>
      </b-field>
      
      <b-field class="pl-4" v-for="answer in localQuestion.answers" :key="answer.id">
        <b-radio
          name="option"
          @input="answerChanged"
          :native-value="answer.id"
          v-model="tmpValue"
        >
          {{ answer.text }}
        </b-radio>
      </b-field>
    </div>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { SERVER } from '@/store/config_apis'

export default {
  name: "ExamQuestionRadio",
  mixins: [CommonHelpers],
  components: {},

  props: {
    question: { type: Object, required: true },
  },

  data: function () {
    return {
      localQuestion: null,
      tmpValue: null,
      answerLocal: [],
    };
  },

  computed: {
    currentImage() {
      if (this.question.image) {
        return `${SERVER}/api/MediaFile/File/${this.question.image}`;
      }

      return null;
    },
  },

  watch: {
    tmpValue() {
      if (this.tmpValue !== null) {
        this.$emit("answered", true);
      } else {
        this.$emit("answered", false);
      }
    },

    question(){
      this.localQuestion = this.question;
      this.setTmpValue();
    },
  },

  // created() {
  //   this.localQuestion = this.question;
  //   this.setTmpValue();
  // },

  mounted(){    
    this.localQuestion = this.question;
    this.questionInterval = setInterval(() => {
      this.question.time++;
    }, 1);
    this.setTmpValue();
  },

  destroyed() {
    clearInterval(this.questionInterval);
  },

  methods: {
    answerChanged(value) {
      this.question.answers.forEach((element) => {
        if (element.id === value) {
          element.isChecked = true;
        } else {
          element.isChecked = false;
        }
      });
    },

    setTmpValue(){      
      this.localQuestion.answers.forEach((element) => {
        if (element.isChecked) {
          this.tmpValue = element.id.toString();
        } 
      });
    }
  },
};
</script>

<style scoped>
</style>