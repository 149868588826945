<template>
  <div>
    <div v-if="localQuestion">
      <b-field class="pb-2"
        ><p class="subtitle">{{ localQuestion.title }}</p></b-field
      >
      <b-field v-if="localQuestion.description">
        <p class="subtitle is-6 is-italic">
          {{t('forms.description')}}:{{ localQuestion.description }}
        </p>
      </b-field>
      <div v-if="currentImage" class="columns is-centered">
        <div class="column is-6">
          <b-field>
            <b-image :src="currentImage" ratio="16by9"> </b-image>
          </b-field>
        </div>
      </div>
      <b-field class="pb-2">
        <p class="subtitle is-5">{{t('question.text')}}: {{ localQuestion.text }}</p>
      </b-field>

      <b-field
        class="pl-4"
        v-for="answer in localQuestion.answers"
        :key="answer.id"
      >
        <b-checkbox
          name="option"
          type="checkbox"
          :checked="answer.isChecked"
          v-model="answer.isChecked"
          @input="changedValue"
          >{{ answer.text }}</b-checkbox
        >
      </b-field>
    </div>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { SERVER } from '@/store/config_apis'

export default {
  name: "ExamQuestionCheckbox",
  mixins: [CommonHelpers],
  components: {},

  props: {
    question: { type: Object, required: true },
  },

  data: function () {
    return {
      localQuestion: null,
      tmpValue: null,
      answerLocal: [],
      questionInterval: null,
    };
  },

  computed: {
    currentImage() {
      if (this.question.image) {
        return `${SERVER}/api/MediaFile/File/${this.question.image}`;
      }

      return null;
    },
  },

  watch: {
    answerLocal() {
      if (this.answerLocal.length > 0) {
        this.$emit("answered", true);
      } else {
        this.$emit("answered", false);
      }
    },

    question() {
      this.localQuestion = this.question;
    },
  },

  mounted() {
    this.localQuestion = this.question;
    this.changedValue();
    this.questionInterval = setInterval(() => {
      this.question.time++;
    }, 1);
  },

  destroyed() {
    clearInterval(this.questionInterval);
  },

  methods: {
    changedValue() {
      var check = 0;
      this.localQuestion.answers.forEach((element) => {
        if (element.isChecked) {
          check++;
        }
      });
      if (check > 0) {
        this.$emit("answered", true);
      } else {
        this.$emit("answered", false);
      }
    },
  },
};
</script>

<style scoped>
</style>